<template>
  <div >
    <create-location-modal ref="locationCreateModal" />
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      taggable
      :placeholder="T('Web.Generic.Locations.Locations', 'Choose or create a location')"
      :options="locations"
      label="name"
      @option:created="handleLocationCreation"
      @option:selected="handleInput"
      @option:deselected="handleInput"
      v-model="content"
      :disabled="disabled"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import CreateLocationModal from "@/components/modals/locations/CreateLocationModal.vue";

export default {
  props: {
    value: { required: true },
    disabled: { required: false, default: false },
    multiple: { required: false, default: false },
    selectedId: { required: false },
    selectedIds: { required: false },
  },
  components: {
    vSelect,
    CreateLocationModal,
  },
  created() {
    if (!this.locations) this.fetchLocations();
    else if (this.selectedId != undefined) {
      let elm = this.locations.find((e) => e.id == this.selectedId);
      if (elm == undefined) return;
      this.setContent(elm);
    } else if (this.selectedIds != undefined) {
      let elm = this.locations.filter((e) => this.selectedIds.includes(e.id));
      if (elm == undefined) return;
      this.setContent(elm);
    }
  },
  data() {
    return {
      content: this.value,
    };
  },
  mounted() {},

  computed: {
    ...mapGetters({ locations: "locations/list" }),
  },

  methods: {
    ...mapActions({ fetchLocations: "locationsOld/fetchList" }),
    handleInput() {
      this.$emit("input", this.content);
    },
    async handleLocationCreation(location) {
      if (typeof location === "object") location = location.name;
      const result = await this.$refs.locationCreateModal.open(location);
      if (!result) return (this.content = null && this.handleInput());
      this.content = this.locations.find((l) => l.id == result.id);
      this.handleInput();
    },
    setContent(value) {
      this.content = value;
    },
  },
  watch: {
    locations(newValue, oldValue) {
      if (this.selectedId != undefined) {
        let elm = this.locations.find((e) => e.id == this.selectedId);
        if (elm == undefined) return;
        this.setContent(elm);
      } else if (this.selectedIds != undefined) {
        let elm = this.locations.filter((e) => this.selectedIds.includes(e.id));
        if (elm == undefined) return;
        this.setContent(elm);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 