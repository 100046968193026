<template>
  <dragablemodal
    :name="'postingeditmodal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <header>
      {{
        T(
          "Web.Generic.Invoices.Reconciliations.PostingEditPopup.Title",
          "Provide information"
        )
      }}
    </header>
    <div class="modals-content">
      <b-form @submit.stop.prevent="handleSubmit" v-if="posting != null">
        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="T('Web.Generic.Postings.PostingType', 'Posting type')"
              label-for="type-input"
            >
              <b-form-select
                id="type-input"
                :options="postingTypeOptions"
                v-model="posting.postingCategory"
              ></b-form-select>
              <small
                class="missing-text"
                v-if="!valueIsNotEmptyOrUnknown(posting.postingCategory)"
                >{{
                  T(
                    "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingType",
                    "Provide type"
                  )
                }}</small
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group :label="T('Web.Generic.Date')" label-for="date-input">
              <b-form-datepicker
                id="date-input"
                autofocus
                trim
                v-model="posting.postingDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"
              />
              <small
                class="missing-text"
                v-if="!valueIsNotEmptyOrUnknown(posting.postingDate)"
                >{{
                  T(
                    "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingDate",
                    "Provide date"
                  )
                }}</small
              >
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              :label="T('Web.Generic.Description')"
              label-for="description-input"
            >
              <b-form-input
                id="description-input"
                trim
                autofocus
                v-model="posting.postingDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineAmount')"
              label-for="quantity-input"
            >
              <b-form-input
                id="quantity-input"
                trim
                v-model="posting.postingQuantity"
                @change="
                  posting.postingQuantity = formatCurrency(
                    posting.postingQuantity
                  )
                "
                lazy
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineUnit')"
              label-for="unit-input"
            >
              <b-form-select
                id="unit-input"
                :options="unitTypeOptions"
                v-model="posting.postingUnit"
              ></b-form-select>
              <small
                class="missing-text"
                v-if="!valueIsNotEmptyOrUnknown(posting.postingUnit)"
                >{{
                  T(
                    "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingUnit",
                    "Provide unite"
                  )
                }}</small
              >
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineUnitPrice')"
              label-for="amount-input"
            >
              <b-form-input
                id="amount-input"
                trim
                type="number"
                v-model="posting.postingUnitPrice"
                @change="
                  posting.postingUnitPrice = formatCurrency(
                    posting.postingUnitPrice
                  )
                "
                lazy
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineTotal')"
              label-for="amount-input"
            >
              <b-form-input
                @change="
                  posting.postingTotalPrice = formatCurrency(
                    posting.postingTotalPrice
                  )
                "
                id="amount-input"
                trim
                type="number"
                v-model="posting.postingTotalPrice"
                lazy
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="!shouldShowSecondaryQuantityFields">
          <b-row class="mb-1 mt-1">
            <b-col cols="12"
              ><a
                href="#"
                @click.prevent="
                  secondaryQuantityFieldsVisible =
                    !secondaryQuantityFieldsVisible
                "
                class="font-weight-bold"
                >{{
                  secondaryQuantityFieldsVisible
                    ? T(
                        "Web.Generic.Invoices.Reconciliations.PostingEditPopup.CloseSecondaryField",
                        "Close Secondary field"
                      )
                    : T(
                        "Web.Generic.Invoices.Reconciliations.PostingEditPopup.OpenSecondaryField",
                        "Open Secondary field"
                      )
                }}<feather-icon
                  icon="ChevronDownIcon"
                  v-if="!secondaryQuantityFieldsVisible" /><feather-icon
                  icon="ChevronUpIcon"
                  v-else /></a
            ></b-col>
          </b-row>
          <b-row v-if="secondaryQuantityFieldsVisible">
            <b-col cols="4">
              <b-form-group
                :label="T('Web.Generic.Invoices.LineAmount')"
                label-for="quantity-input"
              >
                <b-form-input
                  id="quantity-input"
                  trim
                  v-model="posting.postingSecondaryQuantity"
                  type="number"
                  @change="
                    posting.postingSecondaryQuantity = formatCurrency(
                      posting.postingSecondaryQuantity
                    )
                  "
                  lazy
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                :label="T('Web.Generic.Invoices.LineUnit')"
                label-for="unit-input"
              >
                <b-form-select
                  id="unit-input"
                  :options="secondaryUnitTypeOptions"
                  v-model="posting.postingSecondaryUnit"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="shouldShowSecondaryQuantityFields">
          <b-col cols="4">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineAmount')"
              label-for="quantity-input"
            >
              <b-form-input
                id="quantity-input"
                trim
                v-model="posting.postingSecondaryQuantity"
                type="number"
              />
              <small
                class="missing-text"
                v-if="
                  !valueIsNotEmptyOrUnknown(posting.postingSecondaryQuantity)
                "
                >{{
                  T(
                    "Web.Generic.Invoices.Reconciliations.PostingEditPopup.CollectionQuantityMissing",
                    "Provide collection quantity"
                  )
                }}</small
              >
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              :label="T('Web.Generic.Invoices.LineUnit')"
              label-for="unit-input"
            >
              <b-form-select
                id="unit-input"
                :options="unitTypeOptions"
                v-model="posting.postingSecondaryUnit"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit"
          @click="handleSubmit"
        >
          {{ T("Web.Generic.Approve") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ T("Web.Generic.Cancel") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import {
  BModal,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormSelect,
    dragablemodal,
  },
  data() {
    return {
      visible: false,
      secondaryQuantityFieldsVisible: false,
      postingTypeOptions: [
        {
          value: "Unknown",
          text: this.T("Web.Generic.Choose"),
          disabled: true,
        },
        {
          value: "WasteCollection",
          text: this.T("Web.Generic.PostingCategories.WasteCollection"),
        },
        {
          value: "Rent",
          text: this.T("Web.Generic.PostingCategories.Rent"),
        },
        {
          value: "WrongSorting",
          text: this.T("Web.Generic.PostingCategories.WrongSorting"),
        },
        {
          value: "Charge",
          text: this.T("Web.Generic.PostingCategories.Charge"),
        },
        {
          value: "Income",
          text: this.T("Web.Generic.PostingCategories.Income"),
        },

        {
          value: "FutileDriving",
          text: this.T("Web.Generic.PostingCategories.FutileDriving"),
        },
        {
          value: "DeliveryOrRepatriation",
          text: this.T("Web.Generic.PostingCategories.DeliveryOrRepatriation"),
        },
        {
          value: "Exchange",
          text: this.T("Web.Generic.PostingCategories.Exchange"),
        },
        {
          label: this.T("Web.Generic.PostingCategories.Purchase", "Purchase"),
          options: [
            {
              value: "GoodsPurchase",
              text: this.T("Web.Generic.PostingCategories.GoodsPurchase"),
            },
            {
              value: "RepairPurchase",
              text: this.T("Web.Generic.PostingCategories.RepairPurchase"),
            },
            {
              value: "SuppliesPurchase",
              text: this.T("Web.Generic.PostingCategories.SuppliesPurchase"),
            },
            {
              value: "ContainerWash",
              text: this.T("Web.Generic.PostingCategories.ContainerWash"),
            },
          ],
        },
        {
          label: this.T("Web.Generic.PostingCategories.Fee", "Fee"),
          options: [
            {
              value: "RegistrationFee",
              text: this.T("Web.Generic.PostingCategories.RegistrationFee"),
            },
            {
              value: "OilSurchargeFee",
              text: this.T("Web.Generic.PostingCategories.OilSurchargeFee"),
            },
            {
              value: "OtherFees",
              text: this.T("Web.Generic.PostingCategories.OtherFees"),
            },
          ],
        },
      ],
      unitTypeOptions: [
        {
          value: "Unknown",
          text: this.T("Web.Generic.Choose"),
          disabled: true,
        },
        {
          value: "Pcs",
          text: this.T("Web.Generic.Units.Pcs"),
        },
        {
          value: "Tonnage",
          text: this.T("Web.Generic.Units.Tonnage"),
        },
        {
          value: "Kg",
          text: this.T("Web.Generic.Units.Kg"),
        },
        {
          value: "Hours",
          text: this.T("Web.Generic.Units.Hours"),
        },
        {
          value: "Cubic",
          text: this.T("Web.Generic.Units.Cubic"),
        },
      ],
      secondaryUnitTypeOptions: [
        {
          value: "Tonnage",
          text: this.T("Web.Generic.Units.Tonnage"),
        },
        {
          value: "Kg",
          text: this.T("Web.Generic.Units.Kg"),
        },
        {
          value: "Cubic",
          text: this.T("Web.Generic.Units.Cubic"),
        },
      ],
      posting: null,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  computed: {
    canSubmit() {
      return (
        this.posting != null &&
        this.valueIsNotEmptyOrUnknown(this.posting.postingCategory) &&
        this.valueIsNotEmptyOrUnknown(this.posting.postingDate) &&
        this.valueIsNotEmptyOrUnknown(this.posting.postingUnit) &&
        (!this.shouldShowSecondaryQuantityFields ||
          this.valueIsNotEmptyOrUnknown(this.posting.postingSecondaryQuantity))
      );
    },
    shouldShowSecondaryQuantityFields() {
      return (
        this.posting != null &&
        this.posting.postingCategory == "WasteCollection" &&
        this.posting.postingUnit == "Hours"
      );
    },
  },
  methods: {
    open(postingItem) {
      this.posting = postingItem;
      this.posting.postingQuantity = this.formatCurrency(
        this.posting.postingQuantity
      );
      this.posting.postingSecondaryQuantity = this.formatCurrency(
        this.posting.postingSecondaryQuantity
      );
      this.posting.postingUnitPrice = this.formatCurrency(
        this.posting.postingUnitPrice
      );
      this.posting.postingTotalPrice = this.formatCurrency(
        this.posting.postingTotalPrice
      );
      this.$refs.dragablemodal.show();
      this.secondaryQuantityFieldsVisible =
        postingItem.postingSecondaryQuantity > 0;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
     formatCurrency(value, decimal = 2) {
      let floatValue = parseFloat(value);
      return (floatValue < 0.01) ? floatValue.toFixed(3) : floatValue.toFixed(2);
    },
    valueIsNotEmptyOrUnknown(value) {
      return value && value != "Unknown";
    },

    clearForm() {
      this.posting = null;
      this.resolvePromise = undefined;
      this.rejectPromise = undefined;
    },
    handleModalHidden() {
      this.clearForm();
      this.hideModal();
    },
    handleSubmit() {
      this.posting.isSystemGenerated = false;
      this.resolvePromise(true);
      this.hideModal();
    },
    handleCancel() {
      this.resolvePromise(false);
      this.hideModal();
    },
    hideModal() {
      this.$refs.dragablemodal.hide();
    },
  },
  watch: {
    shouldShowSecondaryQuantityFields(newValue) {
      if (newValue) {
        this.posting.postingSecondaryUnit = "Pcs";
      } else {
        this.posting.postingSecondaryUnit = "Unknown";
        this.posting.postingSecondaryQuantity = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.missing-text {
  color: red;
}
</style>